<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="客户端ID" prop="soft_client_id">
        <a-input v-model="desktopConfigs.soft_client_id" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="MQTT地址" prop="mqtt_url">
        <a-input v-model="desktopConfigs.mqtt_url" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="流媒体查询IP列表" prop="stream_ip_list">
        <a-input v-model="desktopConfigs.stream_ip_list" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="流媒体推流域名" prop="push_stream_ip">
        <a-input v-model="desktopConfigs.push_stream_ip" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="流媒体推流端口" prop="push_stream_port">
        <a-input v-model="desktopConfigs.push_stream_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="直播最长播放时长使能" prop="play_duration_enable">
        <a-select v-model="desktopConfigs.play_duration_enable">
          <a-select-option value="0">使能</a-select-option>
          <a-select-option value="1">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="desktopConfigs.play_duration_enable==0" label="直播最长播放时长" prop="play_duration">
        <a-input v-model="desktopConfigs.play_duration" placeholder="" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="控制端存活时间" prop="ulaketc_timing">
        <a-input v-model="desktopConfigs.ulaketc_timing" placeholder="" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="远程控制方式" prop="remote_type">
        <a-select v-model="desktopConfigs.remote_type" placeholder="">
          <a-select-option value="内网">内网</a-select-option>
          <a-select-option value="外网">外网</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="desktopConfigs.remote_type=='内网'" label="远程被控所在内网IP" prop="local_ip">
        <a-input :disabled="true" v-model="desktopConfigs.local_ip" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  getDeviceparamValueList,
  setDeviceparamValueList
} from "A/ai";
import {getCache} from "U/index";
export default {
  name: "desktop",
  data() {
    return {
      desktopConfigs:{
        title_1:"common",
        soft_client_id:"",
        mqtt_url:"",
        stream_ip_list:"",
        play_duration:"",
        play_duration_enable:"",
        push_stream_ip:"",
        push_stream_port:"",
        ulaketc_timing:'',
        remote_type:'',
        local_ip:'',
      },
      monitornum: '',
      paramsdata:[],
      username:'',
      methodList: [],
      tabKey: 1,
      status:false
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.monitornum = this.$route.params.monitornum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    getParamsConfig() {
        let params = {
          devicenum: this.monitornum,
          data: [
            {
              paramstype:1,
              params: {
                method: 'GET',
                url: '/soft-terminal',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.desktopConfigs.title_1=res.item[0].title_1
            this.desktopConfigs.soft_client_id=res.item[0].soft_client_id
            this.desktopConfigs.mqtt_url=res.item[0].mqtt_url
            this.desktopConfigs.stream_ip_list=res.item[0].stream_ip_list
            this.desktopConfigs.play_duration=res.item[0].play_duration
            this.desktopConfigs.play_duration_enable=res.item[0].play_duration_enable
            this.desktopConfigs.push_stream_ip=res.item[0].push_stream_ip
            this.desktopConfigs.push_stream_port=res.item[0].push_stream_port
            this.desktopConfigs.ulaketc_timing=res.item[0].ulaketc_timing
            this.desktopConfigs.remote_type=res.item[0].remote_type
            this.desktopConfigs.local_ip=res.item[0].local_ip
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig() {
      let params = {
        devicenum: this.monitornum,
        data: [
          {
            paramstype:1,
            params: {
              method: 'PUT',
              url: '/soft-terminal',
              body:this.desktopConfigs
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else {
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>