<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="客户端ID" prop="soft_client_ID">
        <a-input v-model="desktopConfigs.soft_client_ID" placeholder="" :disabled="true"></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="心跳周期" prop="heartbeat">-->
<!--        <a-input v-model="desktopConfigs.heartbeat" placeholder="单位：秒"></a-input>-->
<!--      </a-form-model-item>-->
    </a-form-model>
  </div>
</template>

<script>
import {
  getDeviceparamValueList,
  setDeviceparamValueList
} from "A/ai";
import {getCache} from "U/index";
export default {
  name: "remote",
  props:{},
  data() {
    return {
      desktopConfigs:{
        soft_client_ID:"",
        heartbeat:"",
      },
      devicemoduletype: '',
      devicenum: '',
      paramsdata:[],
      username:'',
      methodList: [],
      tabKey: 1,
      status:false
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'GET',
                url: '/smart/netwok/mqttclient?service=servicemqttclient&action=getconfig',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig() {
      let paramsBody = {}
      this.showLoading();
      setDeviceparamValueList(paramsBody).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else {
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>