<!--参数配置页面-->
<template>
  <page-container title="设备详情" :breadcrumb="breadcrumb">
    <div class="tab-container tab-bottom0">
      <a-tabs v-model="devicemoduletype" size="small">
        <a-tab-pane key="1" tab="远程桌面模块"></a-tab-pane>
<!--        <a-tab-pane key="2" tab="远程控制模块"></a-tab-pane>-->
      </a-tabs>
      <div style=""></div>
    </div>
    <div class="detail-page">
      <div class="left-tabs">
        <a-menu @click="clickLeftMenu" :selected-keys="selectedKeys" :style="{ borderRight: 0 }">
          <a-menu-item v-for="item in methodList" :key="item.devparamcfgid">{{item.devparamcfgname}}</a-menu-item>
        </a-menu>
      </div>
      <div class="page-content">
        <div class="right-tabs">
          <desktop v-if="pages=='desktop'" ref="desktop" :Devicenum="devicenum"></desktop>
<!--          <remote v-if="pages=='remote'" ref="desktop"></remote>-->
        </div>
      </div>
    </div>
  </page-container>
</template>
<script>
import desktop from "V/monitorDevice/monitoring/deviceList/params/desktop";
import remote from "V/monitorDevice/monitoring/deviceList/params/remote";
export default {
  name: 'paramsMonitor',
  components: {
    desktop,
    remote,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控软终端',
          path: ''
        },
        {
          name: '设备列表',
          path: ''
        },
        {
          name: '参数配置',
          path: ''
        },
      ],
      devicemoduletype: '',
      devicenum: '',
      methodList: [
          {
            devparamcfgid:0,
            devparamcfgname:'基础参数'
          }
      ],
      desktopFlag:false,
      tabKey: 0,
      paramsList: [],
      pages:'desktop',
      paramList:[],
      parentid:'',
      devparammodel:'',
    }
  },
  computed: {
    selectedKeys() {
      return [this.tabKey]
    }
  },
  watch: {
    devicemoduletype(val) {
      if(val) {
        this.getMethods();
      }
    },
    tabKey(val) {
      if(val) {
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.monitornum = this.$route.params.monitornum||'';
    },
    getMethods() {
    },
    clickLeftMenu(event) {
      this.tabKey = event.key;
      if(this.tabKey==0){this.pages='desktop'};
    },
  }
}
</script>
<style lang="scss" scoped>
.tab-container {
  padding: 0 24px;
  background-color: white;
}
.detail-page {
  display: flex;
  padding: 24px;
  .left-tabs {
    flex-shrink: 0;
    width: 180px;
    min-height: calc(100vh - 200px);
    padding-top: 20px;
    background-color: white;
    border-radius: 2px;
  }
  .page-content {
    flex-grow: 1;
    margin-left: 24px;
  }
  .config-btn {
    padding-right: 4.1%;
    padding-bottom: 10px;
    overflow: hidden;
  }
}
</style>